<template lang="html">
  <PageHeader />
  <section class="background-gray mb-4 mx-4 product-collection home">
    <div class="container">
      <div class="row align-items-center py-3">
        <div class="col-md-6 text-center">
          <div class="row justify-content-center">
            <div class="col-md-9 text-left">
              <img src="@/assets/images/logo-black.png" alt="" width="400" />
              <!-- <p class="mt-4">
                {{ productCollections.description }}
              </p> -->
              <div class="mt-4" v-html="domSanitize(productCollections.html_description)"></div>
              <div class="wave-btn-block">
                <a href="/products/storyphones" class="blob-btn fill-bg btn-lg mt-4">
                  {{ $t('buy') }}
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </a>
                <SvgButton />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <a href="/products/storyphones">
            <!-- <img src="{@/assets/images/storyphones-headset.png}" alt="headset image" /> -->
            <img :src="productCollections?.images[0]?.image_url" alt="headset image" />
          </a>
        </div>
      </div>
    </div>
  </section>

  <section class="background-gray mx-4 mb-4">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <a href="/products/disney">
            <img src="@/assets/images/disney-collections.png" alt="headset image" />
          </a>
        </div>
        <div class="col-md-3 text-center">
          <div class="row justify-content-center">
            <div class="col-md-9">
              <img src="@/assets/images/disney.png" alt="disney image" width="200" class="mt-4" />
              <!-- <p class="mt-2">Disney StoryPhones</p> -->
              <p class="mt-2">{{ productCollections.title }}</p>

              <div class="wave-btn-block">
                <a href="/products/disney" class="blob-btn fill-bg btn-lg mb-4">
                  {{ $t('buy') }}
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </a>
                <SvgButton />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 text-center product-disney">
          <img src="@/assets/images/disney-head.png" alt="" />
        </div>
      </div>
    </div>
  </section>

  <section class=" background-gray mx-4 mb-4">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-5">
          <a href="/products/lingoKids">
            <img src="@/assets/images/greyBundle.png" alt="headset image" />
          </a>
        </div>
        <div class="col-md-3 text-center">
          <div class="row justify-content-center">
            <div class="col-md-9">
              <img src="@/assets/images/Lingokids-logo.png" alt="Lingokids-logo" width="200" class="mt-4" />
              <p class="mt-2">{{ productCollections.description }}</p>
              <!-- <p class="mt-2">{{ productCollections.title }}</p> -->

              <div class="wave-btn-block">
                <a href="/products/lingoKids" class="blob-btn fill-bg btn-lg mb-4">
                  {{ $t('buy') }}
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </a>
                <SvgButton />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 text-center product-disney">
          <img src="@/assets/images/whiteBundle.png" alt="" />
        </div>
      </div>
    </div>
  </section>

  <StoriesCarousel />
  <PageFooter />
</template>

<script lang="js">

import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import DOMPurify from 'dompurify';

import StoriesCarousel from '@/components/partials/StoriesCarousel.vue';
import SvgButton from '@/components/partials/SvgButton.vue';
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';

export default {
  name: 'ProductCollections',
  props: [],
  components: {
    StoriesCarousel,
    SvgButton,
    PageHeader,
    PageFooter,
  },
  setup() {
    const store = useStore();
    const langCode = localStorage.getItem('LangCode');

    const domSanitize = (html) => DOMPurify.sanitize(html);

    const Data = {
      code: langCode,
      slug: `home_product_collections_${langCode}`,
    };
    store.dispatch('fetchCMS', Data);

    const productCollections = computed(() => store.getters.getCMS);

    return {
      productCollections, langCode, domSanitize,
    };
  },
  mounted() {
  },
  methods: {
  },
  computed: {
  },
};
</script>

<style scoped lang="scss"></style>
