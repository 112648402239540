import { useCookies } from 'vue3-cookies';
import CLayerAuth from '@commercelayer/js-auth';
import CLayerBaseService from './CLayerBaseService';

export default class CLayerService extends CLayerBaseService {
  /* Get Sales Channel Token */

  fetchSCChannelToken() {
    return new Promise((resolve, reject) => {
      this.salesChannelToken
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createOrderService() {
    const { cookies } = useCookies({ secure: true });
    return new Promise((resolve, reject) => {
      if (cookies.isKey('clUserAuthCookie')) {
        this.getToken()
          .then(() => {
            this.clEntity.orders
              .create({ language_code: 'en' })
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        this.getToken()
          .then(() => {
            this.clEntity.orders
              .create({
                guest: true,
              })
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }

  checkPendingOrder(customerId) {
    return new Promise((resolve, reject) => {
      const market = localStorage.getItem('marketId');
      this.getToken()
        .then(() => {
          this.clEntity.customers
            .orders(customerId, {
              filters: { status_in: 'pending', market_id_eq: `${market}` },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  changeOrder(orderId) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.orders
            .update({ id: orderId, _refresh: true })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getMarkets() {
    return new Promise((resolve, reject) => {
      this.createAdminToken()
        .then(() => {
          this.clAmdmin.markets
            .list({ include: ['price_list'] })
            .then((response) => {
              const market = localStorage.getItem('marketNumber');
              if (!market) {
                localStorage.setItem('marketNumber', response[4].number);
                localStorage.setItem('marketId', response[4].id);
                localStorage.setItem('marketName', response[4].price_list.name);
              }
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchProductDetailsService(productSKU) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.skus
            .list({
              filters: { code_eq: productSKU },
              fields: { skus: ['prices'] },
              include: ['prices'],
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addLineItem(skuCode, orderId) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.line_items
            .create({
              sku_code: skuCode,
              quantity: 1,
              _update_quantity: true,
              order: { type: 'orders', id: orderId },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getShieldPrice(productSKU) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.skus
            .list({
              filters: { code_eq: productSKU },
              fields: { skus: ['prices', 'stock_items'] },
              include: ['prices', 'stock_items'],
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
              console.log('error');
            });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  getShieldPriceList(productSKU) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.skus
            .list({
              filters: { code_in: productSKU },
              // fields: { skus: ['prices', 'stock_items'] },
              include: ['prices', 'stock_items'],
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
              console.log('error');
            });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  fetchCartItems(orderId) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.orders
            .retrieve(orderId, { include: ['line_items'] })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  clearCartItems(orderId) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.orders
            .delete(orderId)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateLineItem(id, quantity) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.line_items
            .update({ id, quantity })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteLineItem(productId) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.line_items
            .delete(productId)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createAddress(shippingAddress) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.addresses
            .create(shippingAddress)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addAddressToOrder(orderId, shippingAddressId, billingAddressId) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.orders
            .update({
              id: orderId,
              shipping_address: { type: 'addresses', id: shippingAddressId },
              billing_address: { type: 'addresses', id: billingAddressId },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchShippingMethods(id) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.orders
            .shipments(id, { include: ['available_shipping_methods', 'stock_location'] })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchDeliveryLeadTime() {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.delivery_lead_times
            .list({ include: ['shipping_method', 'stock_location'] })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateShippingMethod(shipmentId, shippingMethodId) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.shipments
            .update({
              id: shipmentId,
              shipping_method: { type: 'shipping_methods', id: shippingMethodId },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getPaymentMethods(id) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.orders
            .available_payment_methods(id)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updatePaymentMethods(orderId, paymentMethodId) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.orders
            .update({
              id: orderId,
              payment_method: { type: 'payment_methods', id: paymentMethodId },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createPaymentSource(orderId) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.stripe_payments
            .create({
              order: { type: 'orders', id: orderId },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  stripePayment(orderId) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.stripe_payments
            .create({
              order: { type: 'orders', id: orderId },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateOrder(orderId) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.orders
            .update({
              id: orderId,
              _place: true,
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getOrderHistory(customerId) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.customers
            .orders(customerId, {
              sort: ['-placed_at'],
              filters: { status_in: 'placed,approved,cancelled' },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  associateAddress(addressId) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.customer_addresses
            .create({ address: { id: addressId, type: 'addresses' } })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getAddress() {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.customer_addresses
            .list({ include: ['address'] })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteAddress(id) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.customer_addresses
            .delete(id)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getOrderShippingAddress(orderId) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.orders
            .shipping_address(orderId)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createGuestOrder(orderId, email) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.orders
            .update({ id: orderId, customer_email: email })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getOrderAddress(orderId) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.orders
            .retrieve(orderId)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateAddress(addressId, add) {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(() => {
          this.clEntity.addresses
            .update({
              id: addressId,
              notes: add.notes,
              first_name: add.first_name,
              last_name: add.last_name,
              line_1: add.line_1,
              line_2: add.line_2,
              city: add.city,
              country_code: add.country_code,
              state_code: add.state_code,
              zip_code: add.zip_code,
              phone: add.phone,
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
