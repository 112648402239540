<template lang="html">
  <div v-if="isLoading"><Loader /></div>
  <div v-if="wishListItems" class="row align-items-center">
    <div class="col-md-3 col-lg-2">
      <WishlistImage :cartData="wishListItems" />
    </div>
    <div class="col-md-9 ol-lg-10 m-auto">
      <div class="row mb-3">
        <div class="col-8">
          <h6>
            {{ wishListItems.name }}
          </h6>
        </div>
        <div class="col-4" v-if="wishListItems">
          <h5 class="text-end">
            <span>
              {{ price(wishListItems?.prices[0]) }}
              <!-- {{ price(priceDetails[0]?.prices[0]) }} -->
            </span>
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <div v-if="wishListItems?.stock_items[0]?.quantity > 0" class="wave-btn-block">
            <button
              class="add-to-cart blob-btn fill-bg"
              :class="{ added: isAdded }"
              @click="addLineItem({ itemDetails: wishListItems })"
            >
              <div class="default">
                <i class="icon-cart" aria-hidden="true"></i>{{ $t('AddToCart') }}
              </div>
              <div class="success">{{ $t('added') }}</div>
              <div class="cart">
                <div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div class="dots"></div>
              <span class="blob-btn__inner">
                <span class="blob-btn__blobs">
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                </span>
              </span>
            </button>
            <SvgButton />
          </div>
          <div v-else>
            <button class="add-to-cart disabled" disabled>
              <span class="default"
                ><i class="icon-cart-close me-1" aria-hidden="true"></i>
                {{ $t('outOfStock') }}</span
              >
            </button>
          </div>
        </div>
        <div class="col-4">
          <div class="cart-success-block text-end mt-2">
            <button
              type="button"
              class="border-0 delete"
              aria-label="Remove"
              :class="{ hidecart: deleteSuccess }"
              @click="deleteItem(wishListItems)"
            >
              <i class="icon-delete" aria-hidden="true"></i>
            </button>
            <button
              class="border-0 delete success-only d-none animate__animated animate__jackInTheBox"
              :class="{ addsuccess: deleteSuccess }"
            >
              <i class="icon-checklist" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">

import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  ref, computed, reactive,
} from 'vue';
import { useCookies } from 'vue3-cookies';
import CLayerService from '@/services/CLayerService';
import NewContentfulService from '@/services/NewContentfulService';
import StoryphoneService from '@/services/StoryphoneService';
import Loader from '@/components/commons/Loader.vue';
import WishlistImage from '@/components/partials/WishlistImage.vue';
import SvgButton from '@/components/partials/SvgButton.vue';

const { cookies } = useCookies({ secure: true });
const cLayerService = new CLayerService();
const newContentfulService = new NewContentfulService();
const storyphoneService = new StoryphoneService();

export default {
  name: 'wishlistItem',
  props: ['wishListItems', 'shieldSku'],
  components: {
    Loader,
    WishlistImage,
    SvgButton,
  },
  setup(props) {
    const store = useStore();
    const priceDetails = ref({});
    const productDetails = ref({});
    const isAdded = ref(false);
    const isLoading = ref(false);
    const deleteSuccess = ref(false);
    const quantity = ref(0);

    // contentfulService.getEntityList('products', 100, props.wishListItems.sku)
    //   .then((response) => {
    //     productDetails.value = response.items[0].fields;
    //   })
    //   .catch((error) => {
    //     console.log('error');
    //   });

    const getImage = (details) => details?.image?.fields?.file?.url;

    const deleteItem = (sku) => {
      deleteSuccess.value = true;
      storyphoneService.deleteWishlist(sku.sku)
        .then(() => {
          window.location.reload();
          store.dispatch('fetchUserWishList', { limit: 5, offset: 0 })
            .then(() => {
              deleteSuccess.value = false;
            })
            .catch(() => {
              deleteSuccess.value = false;
            });
        })
        .catch(() => {

        });
    };

    const price = (value) => {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: value.currency_code,
      });
      return formatter.format(value?.amount_float);
    };

    const addLineItem = async (itemSku) => {
      isAdded.value = true;
      await store.dispatch('updateLineItem', itemSku)
        .then((itemAdded) => {
          setTimeout(() => {
            storyphoneService.deleteWishlist(itemSku.itemDetails.sku)
              .then(() => {
                window.location.reload();
                isAdded.value = false;
                store.dispatch('fetchUserWishList', { limit: 5, offset: 0 })
                  .then(() => {
                    isLoading.value = false;
                  })
                  .catch(() => {
                    isLoading.value = false;
                  });
              })
              .catch(() => {
              });
          }, 1000);
        })
        .catch((error) => {
          isAdded.value = false;
        })
        .finally(() => {
        });
    };

    return {
      isLoading, isAdded, productDetails, price, quantity, deleteSuccess, priceDetails, getImage, deleteItem, addLineItem,
    };
  },
};
</script>

<style scoped lang="scss"></style>
