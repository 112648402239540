<template lang="html">
  <PageHeader />

  <section class="mt-stickybar licence-agreement home" aria-label="licence-agreement">
    <div class="inner-page-content py-5">
      <div class="container">
        <div v-html="Details"></div>

        <!-- <div class="row">
          {{ licenseAgreementTermination }}
          <div class="col-md-12">
            <h2>{{ licenseAgreement.title }}</h2>
            <span v-for="(data, index) in licenseAgreement.details" :key="index">
              <p>
                {{ decodeHtml(data.description) }}
              </p>
            </span>
            <span v-for="(widget, index) in licenseAgreement.widgets" :key="index">
              <h5 class="mt-4">{{ widget.title }}</h5>
              <span v-for="(data, index) in widget.details" :key="index">
                <p class="mt-2">
                  {{ decodeHtml(data.description) }}
                </p>
              </span>
            </span>
            <h5>{{ licenseAgreementGeneral.title }}</h5>
            <ul
              v-for="(data, index) in licenseAgreementGeneral.details"
              :key="index"
              class="ms-4 mb-2"
            >
              <li>{{ decodeHtml(data.description) }}</li>
            </ul>

            <span v-for="(widget, index) in licenseAgreementEmail.widgets" :key="index">
              <h5 class="mt-4">{{ widget.title }}</h5>
              <span v-for="(data, index) in widget.details" :key="index">
                <p ref="responseContainer" class="mt-2">
                  {{ decodeHtml(data.description) }}
                </p>
              </span>
            </span>

            <h5>{{ licenseAgreementTermination.title }}</h5>

            <span v-for="(data, index) in licenseAgreementTermination.details" :key="index">
              <p class="mt-2">
                {{ decodeHtml(data.description) }}
              </p>
            </span>
            <span v-for="(widget, index) in licenseAgreementTermination.widgets" :key="index">
              <ol class="ms-4 mb-1">
                <li v-for="(data, index) in widget.details" :key="index">
                  {{ decodeHtml(data.description) }}
                </li>
              </ol>
            </span>
            <p class="mt-2">
              {{ decodeHtml(licenseAgreementTermination.description) }}
            </p>
            <span v-for="(widget, index) in licenseAgreementTransferability.widgets" :key="index">
              <h5 class="mt-4">{{ widget.title }}</h5>
              <span v-for="(data, index) in widget.details" :key="index">
                <p class="mt-2">
                  {{ decodeHtml(data.description) }}
                </p>
              </span>
            </span>
            <h5>{{ licenseAgreementServices.title }}</h5>

            <p class="mt-2">
              {{ decodeHtml(licenseAgreementServices.description) }}
            </p>

            <ul
              v-for="(data, index) in licenseAgreementServices.details"
              :key="index"
              class="ms-4 mb-2"
            >
              <li>{{ decodeHtml(data.description) }}</li>
            </ul>

            <h5>{{ licenseAgreementDisclaimer.title }}</h5>

            <p class="mt-2">
              {{ decodeHtml(licenseAgreementDisclaimer.description) }}
            </p>

            <ul
              v-for="(data, index) in licenseAgreementDisclaimer.details"
              :key="index"
              class="ms-4 mb-2"
            >
              <li>{{ decodeHtml(data.description) }}</li>
            </ul>
            <span v-for="(widget, index) in licenseAgreementDisclaimer.widgets" :key="index">
              <span v-for="(data, index) in widget.details" :key="index">
                <p class="mt-2">
                  {{ decodeHtml(data.description) }}
                </p>
              </span>
            </span>

            <span v-for="(widget, index) in licenseAgreementIndemnification.widgets" :key="index">
              <h5 class="mt-4">{{ widget.title }}</h5>
              <span v-for="(data, index) in widget.details" :key="index">
                <p class="mt-2">
                  {{ decodeHtml(data.description) }}
                </p>
              </span>
            </span>
          </div>
        </div> -->
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import { useStore } from 'vuex';
import { ref, computed } from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import DOMPurify from 'dompurify';
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import PageService from '@/services/PageService';

const pageService = new PageService();
const ent = require('ent');

export default {
  name: 'licence-agreement',
  props: [],
  components: {
    PageHeader,
    PageFooter,
  },
  setup() {
    const store = useStore();
    const responseContainer = ref(null);

    const LangCode = localStorage.getItem('LangCode');
    const data = {
      code: LangCode,
      slug: `license_agreement_${LangCode}`,
    };

    store.dispatch('fetchCMS', data);

    // const data1 = {
    //   code: LangCode,
    //   slug: `license_agreement_general_${LangCode}`,
    // };

    // store.dispatch('fetchCMS1', data1);

    // const data2 = {
    //   code: LangCode,
    //   slug: `license_agreement_email_${LangCode}`,
    // };

    // store.dispatch('fetchCMS2', data2);

    // const data3 = {
    //   code: LangCode,
    //   slug: `license_agreement_termination_${LangCode}`,
    // };

    // store.dispatch('fetchCMS3', data3);

    // const data4 = {
    //   code: LangCode,
    //   slug: `license_agreement_transferability_${LangCode}`,
    // };

    // store.dispatch('fetchCMS4', data4);

    // const data5 = {
    //   code: LangCode,
    //   slug: `license_agreement_services_${LangCode}`,
    // };

    // store.dispatch('fetchCMS5', data5);

    // const data6 = {
    //   code: LangCode,
    //   slug: `license_agreement_disclaimer_${LangCode}`,
    // };

    // store.dispatch('fetchCMS6', data6);

    // const data7 = {
    //   code: LangCode,
    //   slug: `license_agreement_indemnification_${LangCode}`,
    // };

    // store.dispatch('fetchCMS7', data7);

    // const decode = async (value) => {
    //   const text = await ent.decode(value);
    //   return text.value;
    // };

    const licenseAgreement = computed(() => store.getters.getCMS);
    const Details = computed(() => DOMPurify.sanitize(licenseAgreement.value.html_description));

    // const licenseAgreementGeneral = computed(() => store.getters.getCMS1);

    // const licenseAgreementEmail = computed(() => store.getters.getCMS2);

    // const licenseAgreementTermination = computed(() => store.getters.getCMS3);

    // const licenseAgreementTransferability = computed(() => store.getters.getCMS4);

    // const licenseAgreementServices = computed(() => store.getters.getCMS5);

    // const licenseAgreementDisclaimer = computed(() => store.getters.getCMS6);

    // const licenseAgreementIndemnification = computed(() => store.getters.getCMS7);

    // const decodeHtml = (html) => {
    //   const parser = new DOMParser();
    //   const decoded = parser.parseFromString(html, 'text/html').body.textContent;
    //   return decoded;
    // };
    return {
      licenseAgreement,
      // licenseAgreementGeneral,
      // licenseAgreementEmail,
      // licenseAgreementTermination,
      // licenseAgreementTransferability,
      // licenseAgreementServices,
      // licenseAgreementDisclaimer,
      // licenseAgreementIndemnification,
      // decode,
      // decodeHtml,
      responseContainer,
      Details,
    };
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {
  },
};
</script>

<style scoped lang="scss">
.text-link {
  color: blue;
  text-decoration: underline;
}
</style>
