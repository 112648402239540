// eslint-disable-next-line import/no-extraneous-dependencies
import { initializeApp } from 'firebase/app';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDu71vySwva1v-mYOXjYDD4E-XTDALuTp8',
  authDomain: 'storyphones-8d398.firebaseapp.com',
  projectId: 'storyphones-8d398',
  storageBucket: 'storyphones-8d398.appspot.com',
  messagingSenderId: '26526575515',
  appId: '1:26526575515:web:3d7e4974c666695b6dcf3e',
  measurementId: 'G-JCJYJM92C5',
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
