<template lang="html">
  <section class="header">
    <header class="fixed-top">
      <div v-if="isLoading"><Loader /></div>
      <nav class="navbar navbar-light">
        <div class="container">
          <div class="nav-sidebar-outer d-lg-none">
            <button
              class="navbar-toggler border-0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <!-- <span class="navbar-toggler-icon"></span> -->
              <div class="hamburger">
                <div class="bar topopen"></div>
                <div class="bar midopen"></div>
                <div class="bar botopen"></div>
              </div>
            </button>
            <div class="new-header">
              <div
                class="left-sidebar-nav offcanvas offcanvas-start"
                tabindex="-1"
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
                data-bs-backdrop="false"
              >
                <div class="offcanvas-header">
                  <h2 class="offcanvas-title" id="offcanvasNavbarLabel">Welcome!</h2>
                  <button
                    type="button"
                    class="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="offcanvas-body" data-bs-dismiss="offcanvas" aria-label="Close">
                  <MainMenuContent />
                </div>
                <div class="offcanvas-footer">
                  <div class="container mx-5 mt-4">
                    <div>
                      <h6>Choose location</h6>
                      <div
                        class="location-box"
                        data-bs-toggle="modal"
                        data-bs-target="#languagePopModal"
                      >
                        <img class="me-4" :src="`/flag/${LocationImg}.png`" :alt="LocationName" />
                        <p class="mt-1">{{ LocationName }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="new-header-content"> -->
          <div class="mx-5 d-none d-lg-block">
            <div class="location-box" data-bs-toggle="modal" data-bs-target="#languagePopModal">
              <img class="me-4" :src="`/flag/${LocationImg}.png`" :alt="LocationName" />
              <p class="mt-3">{{ LocationName }}</p>
            </div>
          </div>
          <div class="header-search d-lg-none">
            <button
              type="button"
              class="header-serch-icon btn border-0"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <i class="icon-search" aria-hidden="true"></i>
            </button>
          </div>
          <h1 id="logo" class="navbar-brand">
            <a href="/">
              <img src="@/assets/images/logo-black.png" class="img-responsive" alt="" />
            </a>
          </h1>
          <!-- <div class="d-flex justify-content-end"> -->
          <div class="d-none d-lg-block">
            <MainMenuContent />
          </div>
          <!-- <div class="header-search d-lg-block">
            <button
              type="button"
              class="header-serch-icon btn btn-outline-primary border-0"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <i class="icon-search" aria-hidden="true"></i>
            </button>
          </div> -->
          <SideMainContent />
          <!-- <Language /> -->
          <!-- <Currency /> -->

          <!-- <Cart /> -->
          <!-- </div> -->
        </div>
        <!-- </div> -->
      </nav>
    </header>
    <Search />
  </section>
  <!-- Button trigger modal -->

  <!-- Modal -->
  <div
    class="modal fade"
    id="languagePopModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="languagePopModalLabel"
    aria-hidden="true"
  >
    <LocationModal />
  </div>
  <div
    class="modal fade"
    id="marketPlaceLogin"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="languagePopModalLabel"
    aria-hidden="true"
  >
    <MarketPlaceLoginModal />
  </div>
  <div v-if="shippingMessage" class="success-msg alert alert-success">
    <!-- {{ shippingMessage }} -->
    {{ $t('shipMessage') }}
  </div>
  <div v-if="outOfStockMessage" class="success-msg alert alert-danger">
    <!-- {{ outOfStockMessage }} -->
    {{ $t('outOfstockMessage') }}
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { ref, computed, reactive } from 'vue';
import { useCookies } from 'vue3-cookies';
import { useRouter } from 'vue-router';

import Cart from '@/pages/Cart.vue';
import MainMenuContent from '@/components/commons/MainMenuContent.vue';
import SideMainContent from '@/components/commons/SideMainContent.vue';
import CLayerBaseService from '@/services/CLayerBaseService';
import Loader from '@/components/commons/Loader.vue';

import Language from '@/components/commons/Language.vue';
import Search from '@/components/commons/Search.vue';
import Currency from '@/components/commons/Currency.vue';
import MarketPlaceLoginModal from '@/components/partials/MarketPlaceLoginModal.vue';
import LocationModal from '@/components/partials/LocationModal.vue';

const { cookies } = useCookies({ secure: true });
const cLayerBaseService = new CLayerBaseService();

export default {
  name: 'PageHeader',
  props: [],
  components: {
    // Cart,
    // Language,
    Loader,
    MainMenuContent,
    SideMainContent,
    Search,
    // Currency,
    MarketPlaceLoginModal,
    LocationModal,
  },
  setup() {
    const isLoading = ref(false);
    const store = useStore();
    const router = useRouter();
    const market = localStorage.getItem('marketNumber');
    const LocationImg = localStorage.getItem('LocationImg');
    const LocationName = localStorage.getItem('LocationName');

    if (cookies.isKey('orderIdCookie')) {
      const { orderId } = cookies.get('orderIdCookie');
      store.dispatch('fetchCartItems', orderId);
    }
    if ((cookies.isKey('storyPhoneUserAuthCookie'))) {
      // store.dispatch('fetchWishList');
    }

    // const changeLanguage = (data) => {
    //   isLoading.value = true;
    //   localStorage.removeItem('marketNumber');
    //   localStorage.removeItem('marketName');
    //   localStorage.removeItem('update');
    //   localStorage.setItem('marketNumber', data.currencyCode);
    //   localStorage.setItem('marketName', data.currency);

    //   localStorage.removeItem('storyPhoneUser');
    //   localStorage.removeItem('cLayerUserToken');

    //   cookies.remove('clUserAuthCookie');

    //   cookies.remove('orderIdCookie');

    //   cookies.remove('clUserOwnerId');
    //   cookies.remove('clAuthCookie');
    //   cookies.remove('clUserAuthRefreshCookie');
    //   cookies.remove('storyPhoneUserRefreshCookie');
    //   cookies.remove('storyPhoneUserAuthCookie');

    //   cLayerBaseService.createToken()
    //     .then(() => {
    //       const pagePath = router.currentRoute.value.path;
    //       // router.push('/');

    //       if (pagePath === '/') {
    //         window.location.reload();
    //       }
    //       window.location.reload();
    //       isLoading.value = false;
    //     })
    //     .catch((err) => {
    //       isLoading.value = false;
    //     });
    //   localStorage.setItem('LangCode', data.langCode);
    //   localStorage.setItem('LocationImg', data.image);
    //   localStorage.setItem('LocationName', data.name);

    //   window.location.reload();
    // };
    // const europe = computed(() => store.getters.getEurope);

    // localStorage.setItem('LangCode', 'en');
    // localStorage.setItem('LocationImg', 'global');
    // localStorage.setItem('LocationName', 'International');

    const shippingMessage = computed(() => store.getters.shippingMessage);

    const outOfStockMessage = computed(() => store.getters.getOutOfStockMessage);

    return {
      shippingMessage, outOfStockMessage, LocationImg, LocationName, isLoading,
    };
  },
};
</script>

<style scoped lang="scss"></style>
