<template lang="html">
  <!-- {{ cartData }} -->
<h6 v-if="itemName">{{ itemName }}</h6>
</template>

<script lang="js">
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { useCookies } from 'vue3-cookies';

import CLayerService from '@/services/CLayerService';
import NewContentfulService from '@/services/NewContentfulService';

const cLayerService = new CLayerService();
const newContentfulService = new NewContentfulService();
const { cookies } = useCookies({ secure: true });

export default {
  name: 'Cart',
  components: {
  },
  props: ['cartData'],
  setup(props) {
    const store = useStore();
    const router = useRouter();

    // const priceDetails = ref('');
    const itemName = ref('');

    store.dispatch('fetchAvailableCountries');

    const lineItems = computed(() => store.getters.getLineItems);
    const cartItemCount = computed(() => store.getters.getCartItemCount);
    const subtotal = computed(() => store.getters.getSubtotalPrice);

    // store.dispatch('fetchCartItemDetails', props.cartData.sku_code);

    const productDetails = computed(() => store.getters.cartItemDetails);

    newContentfulService.fetchProductsBySku(props.cartData.sku_code)
      .then((response) => {
        itemName.value = response?.data?.items[0]?.title;
      });

    // contentfulService.getEntityList('products', 100, props.cartData.sku_code)
    //   .then((res) => {
    //     priceDetails.value = res.items[0].fields.name;
    //   });

    const clearCart = () => {
      if (cookies.isKey('orderIdCookie')) {
        const { orderId } = cookies.get('orderIdCookie');
        cLayerService.clearCartItems(orderId);
      }
    };

    const checkout = () => {
      router.push('/checkout');
    };

    const library = () => {
      router.push('/shields');
    };

    return {
      lineItems, cartItemCount, subtotal, checkout, library, productDetails, itemName,
    };
  },
};
</script>

<style scoped lang="scss"></style>
