<template lang="html">
  <section class="popup-video">
    <div
      class="modal fade"
      id="popupvideo"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <button
            type="button"
            class="btn-close close-blue-btn ms-auto 45"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="stopVideo()"
          >
            <i class="icon-close" aria-hidden="true"></i>
          </button>

          <iframe
            width="100%"
            height="500"
            :src="this.videosrc"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer;
      autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'PopupVideo',
  props: [],
  setup() {
    const store = useStore();

    const stopVideo = () => {
      store.dispatch('removeVideoSrc', '')
        .then(() => {
          store.dispatch('setvideoSrc', 'https://www.youtube.com/embed/DbEWOVrbd5k?rel=0');
        });
    };

    const videosrc = computed(() => store.getters.getvideoSrc);

    return { stopVideo, videosrc };
  },

};
</script>

<style scoped lang="scss"></style>
