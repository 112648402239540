<template lang="html">
  <section class="cookie-popup">
    <div class="container">
      <div class="row">
        <h3 class="mb-4">StoryPhones & Cookies</h3>

        <div class="col-md-8">
          <p class="mb-4">
            By clicking “Accept all cookies”, you agree StoryPhones can store cookies on your device
            and disclose information in accordance with our Policy Agreement
          </p>
          <!-- <div class="cookie-image">
            <img src="@/assets/images/cookie.png" alt="" />
          </div> -->
        </div>
        <div class="col-md-4">
          <!-- <div class="row"> -->
          <div class="cookie-btn">
            <button @click="goToAgreement" class="cookie-btn-1">View details</button>
            <!-- <button class="btn btn-primary">Reject cookies</button> -->
            <button class="cookie-btn-2" @click="setCookie">Accept</button>
            <!-- <button class="btn btn-primary">Reject cookies</button> -->
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </section>
  <section class="page-footer main-snow-block">
    <footer>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <h4>{{ $t('customerService') }}</h4>
            <ul class="footer-links list-unstyled" aria-label="support pages">
              <li>
                <a href="/contact-us" aria-label="Contact Us" class="txt-bdr-link white-txt">{{
                  $t('contactUs')
                }}</a>
              </li>
              <li>
                <a href="/faq" aria-label="FAQ" class="txt-bdr-link white-txt">{{ $t('faq') }}</a>
              </li>

              <li>
                <a
                  href="/warranty-registration"
                  aria-label="Warranty Registration"
                  class="txt-bdr-link white-txt"
                  >{{ $t('warrantyRegistration') }}</a
                >
              </li>
              <li>
                <!-- download="quickStartGuide-v4.1.pdf" -->
                <a
                  :href="`/pdf/startguide_${langCode}.pdf`"
                  aria-label="Quick Start Guide"
                  class="txt-bdr-link white-txt"
                >
                  {{ $t('quickStart') }}
                </a>
              </li>
              <li>
                <a href="/setup" aria-label="User Manual" class="txt-bdr-link white-txt">{{
                  $t('userManual')
                }}</a>
              </li>
              <li>
                <a
                  href="/refund-policy"
                  aria-label="Refund Policy"
                  class="txt-bdr-link white-txt"
                  >{{ $t('refundPolicy') }}</a
                >
              </li>
              <li>
                <a
                  href="/licence-agreement"
                  aria-label="Licence Agreement"
                  class="txt-bdr-link white-txt"
                  >{{ $t('licenceAgreement') }}</a
                >
              </li>
              <li>
                <!-- download="quickStartGuide-v4.1.pdf" -->
                <a
                  href="/declarationOnConformity/Declaration of CE Conformity-StoryPhones_V1.pdf"
                  aria-label="Declaration of Conformity"
                  class="txt-bdr-link white-txt"
                >
                  {{ $t('conformity') }}
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-2">
            <h4>{{ $t('about') }}</h4>
            <ul class="footer-links list-unstyled">
              <li>
                <a href="/about" aria-label="Our Story" class="txt-bdr-link white-txt">{{
                  $t('ourStory')
                }}</a>
              </li>
              <li>
                <router-link
                  to="/app"
                  aria-label="StoryPhones APP"
                  class="txt-bdr-link white-txt"
                  >{{ $t('storyPhonesAPP') }}</router-link
                >
              </li>
              <li>
                <a href="/blog" aria-label="Blog" class="txt-bdr-link white-txt">{{
                  $t('blog')
                }}</a>
              </li>
            </ul>
          </div>
          <div class="col-md-3">
            <h4>{{ $t('shop') }}</h4>
            <ul class="footer-links list-unstyled">
              <li>
                <a href="/product-collections" class="txt-bdr-link white-txt">{{
                  $t('storyPhonesHeadphones')
                }}</a>
              </li>
              <li>
                <a href="/shields" class="txt-bdr-link white-txt">{{ $t('storyShields') }}</a>
              </li>
              <li>
                <a href="/custom-playlist" class="txt-bdr-link white-txt">{{
                  $t('customPlayShield')
                }}</a>
              </li>
            </ul>
          </div>
          <div class="col-md-4">
            <h4 class="mb-3">ONANOFF LTD.</h4>
            <h6>Hong Kong</h6>
            <p>RM 424, Sino Ind. Plaza, 9 Kai Cheung Road, Kowloon Bay, Kowloon, Hong Kong SAR.</p>

            <h6>{{ $t('us') }}</h6>
            <p>202 Canal Street, Suite 303, New York , NY10013, USA</p>

            <h6>{{ $t('nether') }}</h6>
            <p>Onanoff Europe BV Beneluxlaan 769 Almere, Almere 1363BJ NL</p>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-8">
            <h4>{{ $t('findUsAt') }}</h4>
            <ul class="social-icons mb-4 list-unstyled" aria-label="social icon">
              <li>
                <a href="https://www.facebook.com/StoryPhonesAudio" target="_blank">
                  <i class="icon-facebook" aria-hidden="true"></i>
                </a>
              </li>

              <li>
                <a href="https://www.instagram.com/storyphonesaudio/" target="_blank">
                  <i class="icon-instagram" aria-hidden="true"></i>
                </a>
              </li>
              <!-- <li>
                <a href="https://www.linkedin.com/company/storyphones/" target="_blank">
                  <i class="icon-linkedin" aria-hidden="true"></i>
                </a>
              </li> -->
            </ul>
          </div>
          <div class="col-md-4">
            <div>
              <h4>Choose location</h4>
              <div class="location-box" data-bs-toggle="modal" data-bs-target="#languagePopModal">
                <img
                  v-if="LocationImg === 'global'"
                  class="me-4"
                  src="@/assets/images/global.png"
                  :alt="LocationName"
                />
                <img v-else class="me-4" :src="`/flag/${LocationImg}.png`" :alt="LocationName" />
                <p class="mt-3">{{ LocationName }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </section>
  <div
    class="modal fade"
    id="languagePopModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="languagePopModalLabel"
    aria-hidden="true"
  >
    <LocationModal />
  </div>
</template>

<script lang="js">
import { ref, onMounted } from 'vue';
import { useCookies } from 'vue3-cookies';
import { useRouter } from 'vue-router';
import LocationModal from '@/components/partials/LocationModal.vue';

const { cookies } = useCookies({ secure: true });
export default {
  name: 'PageFooter',
  props: [],
  components: {
    LocationModal,
  },
  setup() {
    const langCode = ref('');
    const router = useRouter();

    const LocationImg = localStorage.getItem('LocationImg');
    const LocationName = localStorage.getItem('LocationName');
    langCode.value = localStorage.getItem('LangCode');
    const privacyCookie = cookies.get('privacyCookie');
    onMounted(() => {
      const cookieContainer = document.querySelector('.cookie-popup');
      if (!privacyCookie) {
        cookieContainer.style.display = 'block';
      } else {
        cookieContainer.style.display = 'none';
      }
    });
    const goToAgreement = () => {
      router.push('/licence-agreement');
    };
    const setCookie = () => {
      const cookieContainer = document.querySelector('.cookie-popup');
      const keyValue = 'Accepted';
      cookies.set('privacyCookie', keyValue);
      cookieContainer.style.display = 'none';
    };
    return {
      setCookie,
      langCode,
      goToAgreement,
      LocationImg,
      LocationName,
    };
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
