<template lang="html">
  <div v-if="isLoading">
    <Loader />
  </div>
  <section class="download-app" aria-label="download app">
    <div class="container">
      <div class="row justify-content-end align-items-center">
        <div class="col-md-4 text-center order-2 order-lg-1 order-md-1">
          <img src="@/assets/images/download-img.png" alt="mobile app image" class="mobile-app-img" />
        </div>
        <div class="col-md-7 order-1 order-lg-1 order-md-1">
          <div class="heading-with-highlight" v-html="Details"></div>
          <div class="app-store-pic d-flex">

            <a href="https://apps.apple.com/in/app/storyphones-by-onanoff/id1563872709" target="_blank">
              <img src="@/assets/images/app-store.jpg" alt="app image" class="my-4 me-3" width="165" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.onanoff.storyphones" target="_blank">
              <img src="@/assets/images/google-play.jpg" alt="app image" class="my-4 me-3" width="180" />
            </a>
            <div>
              <a
                href="https://apk-folder.s3.eu-central-1.amazonaws.com/storyphones.apk"
                target="_blank"
              >
                <img src="@/assets/images/direct-download.png" alt="app image" class="my-4" width="180" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="download-image">
      <img src="@/assets/images/image5.png" alt="image" />
    </div>
  </section>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import DOMPurify from 'dompurify';

import Loader from '@/components/commons/Loader.vue';

export default {
  name: 'DownloadApp',
  props: [],
  components: {
    Loader,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const langCode = localStorage.getItem('LangCode');
    isLoading.value = true;
    const Data = {
      code: langCode,
      slug: `app_header_${langCode}`,
    };
    store.dispatch('fetchCMS6', Data)
      .then(() => {
        isLoading.value = false;
      });

    const appHeader = computed(() => store.getters.getCMS6);

    const Details = computed(() => DOMPurify.sanitize(appHeader.value.html_description));

    return {
      langCode, appHeader, isLoading, Details,
    };
  },
  mounted() {
  },
  data() {
    return {
    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
