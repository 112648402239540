<template>
  <div v-if="user && shieldData?.availability_status != 'coming_soon'" class="wishlist-success-block top-heart">
    <button
      type="button"
      class="root border-0 p-0 animate__animated animate__jackInTheBox"
      :class="{ active: isOnWishlist }"
      @click="isOnWishlist ? removeFromWishlist(shieldData) : addToWishlist(shieldData)"
    >
      <WishListSvgButton />
    </button>
  </div>
  <div v-if="shieldData?.availability_status === 'coming_soon'" class="wave-btn-block">
    <button class="blob-btn fill-bg btn-lg">
      Coming Soon...
      <span class="blob-btn__inner">
        <span class="blob-btn__blobs">
          <span class="blob-btn__blob"></span>
          <span class="blob-btn__blob"></span>
          <span class="blob-btn__blob"></span>
          <span class="blob-btn__blob"></span>
        </span>
      </span>
    </button>
  </div>
  <div v-else-if="shieldData?.stock_items[0]?.quantity > 0 || quantity > 0" class="wave-btn-block">
    <button
      class="add-to-cart blob-btn fill-bg"
      :class="{ added: isAdded }"
      @click="addLineItem({ itemDetails: shieldData })"
    >
      <div class="default"><i class="icon-cart" aria-hidden="true"></i> {{ $t('AddToCart') }}</div>
      <div class="success">{{ $t('added') }}</div>
      <div class="cart">
        <div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="dots"></div>
      <span class="blob-btn__inner">
        <span class="blob-btn__blobs">
          <span class="blob-btn__blob"></span>
          <span class="blob-btn__blob"></span>
          <span class="blob-btn__blob"></span>
          <span class="blob-btn__blob"></span>
        </span>
      </span>
    </button>
    <SvgButton />
  </div>
  <div v-else>
    <button class="add-to-cart disabled" disabled>
      <span class="default"
        ><i class="icon-cart-close me-1" aria-hidden="true"></i> {{ $t('outOfStock') }}</span
      >
    </button>
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { useCookies } from 'vue3-cookies';

import CLayerBaseService from '@/services/CLayerBaseService';
import StoryphoneService from '@/services/StoryphoneService';
import WishListSvgButton from '@/components/partials/WishListSvgButton.vue';
import CLayerService from '@/services/CLayerService';
import SvgButton from '@/components/partials/SvgButton.vue';

const cLayerService = new CLayerService();
const { cookies } = useCookies({ secure: true });
const cLayerBaseService = new CLayerBaseService();
const storyphoneService = new StoryphoneService();

export default {
  name: 'ShieldListingButton',
  props: ['shieldData', 'shieldSku', 'zenShield'],
  components: {
    WishListSvgButton,
    SvgButton,
  },
  setup(props) {
    const store = useStore();
    const isAdded = ref(false);
    const isLoading = ref(false);
    const user = ref({});
    const isOnWishlist = ref(false);
    const quantity = ref(0);

    const allWishlist = computed(() => store.getters.getUserWishlist?.data?.items);

    user.value = cookies.get('storyPhoneUserAuthCookie');

    if (user.value) {
      store.dispatch('fetchUserWishList', { limit: 0, offset: 0 })
        .then(() => {
          if (allWishlist.value.length > 0) {
            allWishlist.value.forEach((element) => {
              if (element.sku === props.shieldData.sku) {
                isOnWishlist.value = true;
              }
            });
          }
        });
    }

    if (props.zenShield) {
      cLayerService.getShieldPrice(props.zenShield)
        .then((response) => {
          quantity.value = response[0]?.stock_items[0]?.quantity;
        })
        .catch((error) => {
          console.log('error');
        });
    }

    const addLineItem = (lineItem) => {
      isLoading.value = true;
      isAdded.value = true;
      store.dispatch('updateLineItem', lineItem)
        .then((itemAdded) => {
          setTimeout(() => {
            isAdded.value = false;
          }, 2000);
        })
        .catch((error) => {
          isAdded.value = false;
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const addToWishlist = (data) => {
      isOnWishlist.value = true;
      const sku = {
        sku: data.sku,
        title: data.name,
      };
      store.dispatch('addToWishlist', sku);
    };

    const removeFromWishlist = (data) => {
      isOnWishlist.value = false;
      storyphoneService.deleteWishlist(data.sku)
        .then(() => {
          store.dispatch('fetchUserWishList', { limit: 0, offset: 0 });
        });
    };

    return {
      isAdded,
      isLoading,
      user,
      allWishlist,
      addLineItem,
      addToWishlist,
      removeFromWishlist,
      isOnWishlist,
      quantity,
    };
  },
};
</script>
