/* eslint-disable indent */
import { useCookies } from 'vue3-cookies';
import BaseService from './BaseService';

const { cookies } = useCookies({ secure: true });

export default class StoryphoneService extends BaseService {
  fetchBackgroundColours(stateCode) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/background-colors/`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchStoryshields(stateCode) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/storyshields/?locale=${stateCode}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchProductsBySku(skuList) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/products/?&sku__in=${skuList}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchStoryshieldBySku(data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/storyshields/by-sku/${data.sku}?locale=${data.locale}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchStory(stateCode) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/storyshields/?locale=${stateCode}&limit=5&is_new__eq=true`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchStoryshieldsDetails(Data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/storyshields/${Data.id}?locale=${Data.locale}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchPlaylistDetails(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/playlists/${id}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchGenresDetails(Data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/playlist-genres/${Data.id}?locale=${Data.locale}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchShielsByGenres(id, count, page, locale) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/storyshields/?limit=${count}&locale=${locale}&offset=${page}&genre__eq=${id}&orderBy=updated_at`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchAllGenresIds(count, offset, locale) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/playlist-genres/?limit=${count}&offset=${offset}&orderBy=-updated_at`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchStoryphoneBySku(data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/storyphones/by-sku/${data.sku}?locale=${data.locale}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchZenShields(count, offset) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/zenshield/`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchZenShieldsById(data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/zenshield/by-sku/${data.sku}?locale=${data.locale}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchOnanoffStoryPhones(locale) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/storyphones/?brand__eq=onanoff&locale=${locale}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchStoryPhonesBySku(Data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/storyphones/by-sku/${Data.id}?locale=${Data.locale}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchDisneyStoryPhones(locale) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/storyphones/?brand__eq=disney&locale=${locale}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchLingoKidsStoryPhones(locale) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/storyphones/?brand__eq=lingokids-onanoff&locale=${locale}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchPlayShieldPlus(locale) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/playshield/?locale=${locale}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchSearchResults(term) {
    return new Promise((resolve, reject) => {
      let apiEndPoint;
      if (term.searchText.value) {
        apiEndPoint = `${this.baseURL}v1/cms/products?product_category__in=storyshield,zenshield,storyphones&search=${term.searchText.value}&limit=10&offset=${term.currentOffset.value}`;
      } else if (term.category) {
        apiEndPoint = `${this.baseURL}v1/cms/products?product_category__in=${term.category}&limit=10&offset=${term.currentOffset.value}`;
      } else if (!term.searchText.value && !term.category) {
        apiEndPoint = `${this.baseURL}v1/cms/products?limit=10&offset=${term.currentOffset.value}&product_category__in=storyshield,zenshield,storyphones`;
      } else {
        apiEndPoint = `${this.baseURL}v1/cms/products?product_category__in=${term.category}&search=${term.searchText.value}&limit=10&offset=${term.currentOffset.value}`;
      }

      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchPlayShieldPlusByLineItems(Data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/line-items/order-reference-id/${Data.refId}/sku/${Data.sku}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deletePlayShieldPlusByLineItems(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/line-items/${id}`;
      this.http
        .delete(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getComingSoon(limit = 5) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/storyshields?availability_status__eq=coming_soon&limit=${limit}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'guest-token': this.guestToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
