<template lang="html">
  <section class="popup-zen-shield">
    <div
      class="modal fade"
      id="popupzenshield"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-0">
            <button
              type="button"
              class="btn-close close popup"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="icon-close" aria-hidden="true"></i>
            </button>
            <div class="row m-0 align-items-center">
              <div v-if="selectedShieldDetails.image" class="col-md-6 p-0">
                <div class="shield-popup-image text-center">
                  <img :src="selectedShieldDetails.image" alt="" />
                </div>
              </div>
              <div v-if="selectedShieldDetails" class="col-md-6 p-0">
                <div class="shield-popup-content">
                  <div class="shield-plus">
                    <h2>ZenShield</h2>
                    <h4 class="highlight-heading" v-if="priceDetails[0]?.prices[0]">
                      {{ price(priceDetails[0]?.prices[0]) }}
                      <!-- {{ priceDetails[0]?.prices[0]?.formatted_amount.slice(0, 1) }}
                      {{ priceDetails[0]?.prices[0]?.amount_float }} -->
                    </h4>
                    <p>
                      {{selectedShieldDetails.title}}
                    </p>
                    <hr />
                    <h5>{{ $t('introduction') }}:</h5>
                    <p>
                      {{selectedShieldDetails.description}}
                    </p>
                  </div>

                  <div class="cart-success-block mt-3">
                    <div v-if="quantity > 0" class="wave-btn-block">
                      <button
                        class="add-to-cart blob-btn fill-bg"
                        :class="{ added: isAdded }"
                        @click="addToCart()"
                      >
                        <div class="default">
                          <i class="icon-cart" aria-hidden="true"></i> {{ $t('AddToCart') }}
                        </div>
                        <div class="success">{{ $t('added') }}</div>
                        <div class="cart">
                          <div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                        <div class="dots"></div>
                        <span class="blob-btn__inner">
                          <span class="blob-btn__blobs">
                            <span class="blob-btn__blob"></span>
                            <span class="blob-btn__blob"></span>
                            <span class="blob-btn__blob"></span>
                            <span class="blob-btn__blob"></span>
                          </span>
                        </span>
                      </button>
                      <SvgButton />
                    </div>
                    <div v-else>
                      <button class="add-to-cart disabled" disabled>
                        <div class="default">
                          <i class="icon-cart" aria-hidden="true"></i> {{ $t('outOfStock') }}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">

import { useStore } from 'vuex';
import { ref, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';

import CLayerService from '@/services/CLayerService';
import SvgButton from '@/components/partials/SvgButton.vue';
import NewContentfulService from '@/services/NewContentfulService';

const cLayerService = new CLayerService();
const newContentfulService = new NewContentfulService();

export default {
  name: 'popup-zen-shield',
  props: [],
  components: {
    SvgButton,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isAdded = ref(false);
    const priceDetails = ref({});
    const quantity = ref(0);
    const langCode = localStorage.getItem('LangCode');

    const getPrice = (sku) => {
      cLayerService.getShieldPrice(sku)
        .then((response) => {
          priceDetails.value = response;
          quantity.value = response[0]?.stock_items[0]?.quantity;
        })
        .catch((error) => {
        });
    };

    const selectedShieldDetails = computed(() => store.getters.getSelectedShieldDetails);

    newContentfulService.fetchZenShields()
      .then((response) => {
        const skuCode = response?.items[0]?.sku;
        const data = {
          sku: response?.items[0]?.sku,
          locale: langCode,
        };
        store.dispatch('fetchSelectedZenShieldDetails', data)
          .then(() => {
            getPrice(selectedShieldDetails.value.sku);
          });
      });

    const zenShieldList = computed(() => store.getters.getZenShieldList);

    const price = (value) => {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: value.currency_code,
      });
      return formatter.format(value?.amount_float);
    };

    const addToCart = () => {
      const data = {
        itemDetails: {
          sku: 4897111740835,
        },
      };
      isAdded.value = true;
      store.dispatch('updateLineItem', data)
        .then((itemAdded) => {
          setTimeout(() => {
            isAdded.value = false;
          }, 2000);
        })
        .catch((error) => {
          isAdded.value = false;
        });
    };

    return {
      isAdded,
      quantity,
      priceDetails,
      addToCart,
      price,
      zenShieldList,
      selectedShieldDetails,
    };
  },

};
</script>

<style scoped lang="scss"></style>
