import { createI18n } from 'vue-i18n';
import store from '../store';

import en from './en.json';
import it from './it.json';
import de from './de.json';
import fr from './fr.json';
import es from './es.json';
import chi from './chi.json';

const langCode = localStorage.getItem('LangCode');
if (!langCode) {
  localStorage.setItem('LangCode', 'en');
}

const translation = createI18n({
  legacy: false,
  locale: localStorage.getItem('LangCode'),
  messages: {
    en,
    it,
    de,
    fr,
    es,
    chi,
  },
});

export default translation;
