<template lang="html">
  <div v-if="isLoading"><Loader /></div>
  <div class="modal-body px-4">
    <div class="row">
      <div class="col-md-6 text-center">
        <img v-if="this.shieldData" :src="this.shieldData?.image" alt="" />
        <div v-if="this.shieldData?.brand?.name" class="col-md-12 mt-4">
            <img :src="this.shieldData?.brand?.image" alt="" width="200" />
        </div>
        <button
        v-if="shieldData?.sample_audio"
          type="button"
          class="blob-btn fill-bg play-btn mt-3 mb-2"
          data-bs-target="#shield-play-popup"
          data-bs-toggle="modal"
        >
          <span class="play-arrow me-1"><i class="icon-right-arrow" aria-hidden="true"></i></span>
          {{ $t('play') }}
          <span class="blob-btn__inner">
            <span class="blob-btn__blobs">
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
            </span>
          </span>
        </button>
      </div>

      <div class="col-md-6 text-left">
        <h1>{{ this.shieldData?.title }}</h1>
        <h1 v-if="this.shieldData?.name">{{ this.shieldData?.name }}</h1>
        <h4 v-if="priceDetails[0]?.prices[0]">
          {{ price(priceDetails[0]?.prices[0]) }}
        </h4>

        <div v-if="this.shieldData">

          <p v-if="this.shieldData.description" class="mt-3">
            {{ this.shieldData.description }}
          </p>

          <ol v-if="this.shieldData?.informations" class="ps-3">
            <li
              class="w-100 p-0 m-0"
              v-for="(storyList, index) in this.shieldData?.informations"
              :key="index"
            >
              {{ storyList }}
            </li>
          </ol>
        </div>
        <div v-if="this.shieldData?.age">
            <p>&copy;{{this.shieldData?.brand?.name}}</p>
        </div>

        <div class="btn-main-box d-flex">
          <div v-if="this.shieldData.availability_status === 'coming_soon'" class="wave-btn-block mt-3">
            <button class="blob-btn fill-bg btn-lg">
              Coming Soon...
              <span class="blob-btn__inner">
                <span class="blob-btn__blobs">
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                </span>
              </span>
            </button>
          </div>
          <div v-else-if="quantity > 0" class="wave-btn-block mt-3">
            <button
              class="add-to-cart blob-btn fill-bg"
              :class="{ added: isAdded }"
              @click="addLineItem({ sku: this.shieldSku })"
            >
              <div class="default">
                <i class="icon-cart" aria-hidden="true"></i> {{ $t('AddToCart') }}
              </div>
              <div class="success">{{ $t('added') }}</div>
              <div class="cart">
                <div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div class="dots"></div>
              <span class="blob-btn__inner">
                <span class="blob-btn__blobs">
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                  <span class="blob-btn__blob"></span>
                </span>
              </span>
            </button>
            <SvgButton />
          </div>
          <div v-else class="mt-3">
            <button class="add-to-cart disabled" disabled>
              <span class="default"
                ><i class="icon-cart-close me-1" aria-hidden="true"></i>
                {{ $t('outOfStock') }}</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- {{ shieldData }} -->
    <hr />
    <div v-if="this.shieldData?.age" class="row" >
      <div class="col-sm-4 mb-4 category-details">
        <h6>{{ $t('age') }}:</h6>
        <p>{{ this.shieldData?.age }}+</p>
      </div>
      <div class="col-sm-4 mb-4 category-details">
        <h6>{{ $t('duration') }}:</h6>
        <!-- <p>{{ toTime(this.shieldData[0]?.fields?.playlist?.fields?.duration) }}</p> -->
        <p>{{ toTime(playlistDetail?.duration) }}</p>
      </div>
      <!-- <div  class="col-sm-3 mb-4 category-details">
        <h6>{{ $t('category') }}:</h6>
        <p>{{ getGenresDetail?.title }}</p>
      </div> -->
      <div class="col-sm-4 mb-4 category-details">
        <h6>{{ $t('language') }}:</h6>
        <p>{{ this.shieldData?.languages }}</p>
      </div>
    </div>
  </div>

  <div class="details-carousel">
    <h4>{{ $t('umaylike') }}</h4>
    <PopupCarouselListing :showCount="3" />
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { useCookies } from 'vue3-cookies';

import PopupCarouselListing from '@/components/partials/PopupCarouselListing.vue';
import Loader from '@/components/commons/Loader.vue';
import CLayerService from '@/services/CLayerService';
import StoryphoneService from '@/services/StoryphoneService';
import SvgButton from '@/components/partials/SvgButton.vue';
import NewContentfulService from '@/services/NewContentfulService';

const { cookies } = useCookies({ secure: true });
const storyphoneService = new StoryphoneService();
const cLayerService = new CLayerService();
const newContentfulService = new NewContentfulService();

export default {
  name: 'DetailsOfCategory',
  props: ['shieldData', 'shieldSku'],

  components: {
    PopupCarouselListing,
    SvgButton,
    Loader,
  },

  setup(props) {
    const store = useStore();
    const isAdded = ref(false);
    const priceDetails = ref({});
    const quantity = ref(0);
    const isLoading = ref(false);
    isLoading.value = true;
    // const isWishlistAdded = ref(false);
    // const isWishlistNotAdded = ref(true);
    // const user = ref({});
    // user.value = cookies.get('clUserAuthCookie');
    store.dispatch('fetchPlaylistDetail', props.shieldData.playlist);
    // const details = {
    //     id: Data.genre,
    //     locale: langCode,
    //   };
    //   store.dispatch('fetchGenresDetail', details);

    const playlistsImage = computed(() => store.getters.getPlaylistsImage);

    const playlistDetail = computed(() => store.getters.getPlaylistDetails);

    const getGenresDetail = computed(() => store.getters.getGenresDetails);

    // const allWishlist = computed(() => store.getters.getWishlist);

    // if (user.value) {
    //   if (allWishlist.value.length > 0) {
    //     allWishlist.value.forEach((element) => {
    //       if (element.sku === props.shieldSku.sku) {
    //         isWishlistAdded.value = true;
    //         isWishlistNotAdded.value = false;
    //       }
    //     });
    //   }
    // }
    // isLoading.value = true;fetchGenresDetails
    // newContentfulService.fetchPlaylistDetails(props?.shieldData?.playlist).then((response) => {
    //   playlistDetails.value = response;
    // });

    cLayerService.getShieldPrice(props.shieldSku)
      .then((response) => {
        priceDetails.value = response;
        quantity.value = response[0]?.stock_items[0]?.quantity;
        isLoading.value = false;
      })
      .catch((error) => {
      });

    // const toTime = (seconds) => {
    //   const date = new Date();
    //   date.setSeconds(seconds);
    //   return date.toISOString().substr(11, 8);
    //   // const totalMs = totalSeconds * 1000;
    //   // const result = new Date(totalMs).toISOString().slice(11, 19);

    //   // return result;
    // };
    const toTime = (seconds) => {
      const date = new Date(seconds * 1000); // Convert seconds to milliseconds
      const hours = date.getUTCHours().toString().padStart(2, '0');
      const minutes = date.getUTCMinutes().toString().padStart(2, '0');
      const second = date.getUTCSeconds().toString().padStart(2, '0');
      const formattedTime = `${hours}:${minutes}:${second}`;
      return formattedTime;
    };

    const addLineItem = (lineItem) => {
      isAdded.value = true;
      const data = {
        itemDetails: lineItem,
      };
      store.dispatch('updateLineItem', data)
        .then((itemAdded) => {
          setTimeout(() => {
            isAdded.value = false;
          }, 2000);
        })
        .catch((error) => {
          isAdded.value = false;
        })
        .finally(() => {
        });
    };

    const price = (value) => {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: value.currency_code,
      });
      isLoading.value = false;
      return formatter.format(value?.amount_float);
    };

    // const addToWishlist = (data) => {
    //   isWishlistNotAdded.value = false;
    //   isWishlistAdded.value = true;
    //   const sku = {
    //     sku: data.sku,
    //     title: data.name,
    //   };
    //   store.dispatch('addToWishlist', sku);
    // };

    // const removeFromWishlist = (data) => {
    //   isWishlistNotAdded.value = true;
    //   isWishlistAdded.value = false;
    //   storyphoneService.deleteWishlist(data.sku)
    //     .then(() => {
    //       store.dispatch('fetchWishList');
    //     });
    // };
    return {
      isAdded, playlistsImage, priceDetails, toTime, price, addLineItem, quantity, isLoading, playlistDetail, getGenresDetail,
    };
  },
};
</script>

<style scoped lang="scss"></style>
