<template lang="html">
  <div v-if="getProductCategory(productDetails.product_category)" class="col-5">
    <!-- {{ cartData }} -->
    <a
      @click="shieldClick(cartData.sku_code)"
      class="pic"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    >
      <img :src="cartData.image_url" alt="cart image" />
    </a>
  </div>
  <div v-else class="col-5">
    <img :src="cartData.image_url" alt="cart image" />
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';

import NewContentfulService from '@/services/NewContentfulService';
import CLayerService from '@/services/CLayerService';

const cLayerService = new CLayerService();
const newContentfulService = new NewContentfulService();

export default {
  name: 'CartItemImage',
  props: [
    'cartData',
  ],
  setup(props) {
    const langCode = localStorage.getItem('LangCode');
    const store = useStore();
    const router = useRouter();
    const productDetails = ref({});
    const category = ref(['storyshield']);
    // contentfulService.getEntityList('products', 100, props.cartData.sku_code)
    //   .then((response) => {
    //     productDetails.value = response.items[0].fields;
    //   })
    //   .catch((error) => {
    //     console.log('error');
    //   });

    newContentfulService.fetchProductsBySku(props.cartData.sku_code)
      .then((response) => {
        productDetails.value = response?.data?.items[0];
      })
      .catch((error) => {
        console.log('error');
      });

    const getProductCategory = (name) => {
      // eslint-disable-next-line no-constant-condition
      if (category.value.includes(name)) {
        return true;
      }
      return false;
    };

    const shieldClick = (sku) => {
      const Data = {
        id: sku,
        locale: langCode,
      };

      store.dispatch('fetchCartItemDetails', Data);
      store.dispatch('fetchCartItemPrice', sku);
      router.push({ name: 'cart-details', params: { id: sku } }).then(() => { router.go(); });
    };

    return {
      productDetails, category, getProductCategory, shieldClick,
    };
  },
};
</script>
